import {
    BootResponse,
    OfferData,
    SectionData
} from '../../constants/apiResponses.types';
import useApi from '../../hooks/useApi';
import {
    BagelCodeSection,
    BundlesLayout,
    DefaultSection,
    PipaSection
} from '@appcharge/shared-ui';
import {
    EBundlesInternalViewModel,
    ESectionsViewModel
} from '../../constants/enums';
import { Bundle } from '../Bundle/Bundle';
import { Box } from '@mui/material';
import { BundlesListProps } from './bundles-list.types';
import { useEffect, useState } from 'react';
import './styles.scss';

const sectionDesignDic = {
    [ESectionsViewModel.DEFAULT]: DefaultSection,
    [ESectionsViewModel.BAGEL_CODE]: BagelCodeSection,
    [ESectionsViewModel.PIPA]: PipaSection
};

export const BundlesList = ({ selectOffer }: BundlesListProps) => {
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const { bundlesViewModel, sectionViewModel, bundlesInternalViewModel } =
        publisherMetaData.storeTheme.general;
    const isUsingApiV2 =
        publisherMetaData.featureFlags?.store_daily_bonus_badge;
    const sections = API.getOffers?.data?.sections as SectionData[];
    const [isProductMadness, setIsProductMadness] = useState(false);

    const SectionDesign =
        sectionDesignDic[sectionViewModel || ESectionsViewModel.DEFAULT];
    const [bundlesBySection, setBundlesBySection] = useState(
        {} as Record<string, OfferData[]>
    );

    const [bundlesWithoutSections, setBundlesWithoutSections] = useState(
        [] as OfferData[]
    );

    useEffect(() => {
        if (!API.getOffers?.data) return;

        const bundles = isUsingApiV2
            ? API.getOffers?.data?.offers?.bundles
            : API.getOffers?.data?.offers?.filter(
                  (offer: OfferData) => offer.offerType === 'Bundle'
              );

        const newBundleBySection: Record<string, OfferData[]> = {};
        const newBundlesWithoutSections: OfferData[] = [];

        bundles?.forEach((bundle: OfferData) => {
            const sectionId = bundle.offerSectionId || 'no-section';
            const targetArray =
                sectionId === 'no-section'
                    ? newBundlesWithoutSections
                    : (newBundleBySection[sectionId] ||= []);
            targetArray.push(bundle);
        });

        // Order bundles according to sections
        const orderedBundles = Object.fromEntries(
            sections
                .filter(
                    (section) => newBundleBySection[section.sectionId]?.length
                )
                .map((section) => [
                    section.sectionId,
                    newBundleBySection[section.sectionId]
                ])
        );
        setIsProductMadness(
            [
                EBundlesInternalViewModel.JACKPOT_SLOTS,
                EBundlesInternalViewModel.BIG_FISH_CASINO,
                EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
                EBundlesInternalViewModel.HEART_OF_VEGAS
            ].includes(
                publisherMetaData.storeTheme.general.bundlesInternalViewModel
            )
        );
        setBundlesBySection(orderedBundles);
        setBundlesWithoutSections(newBundlesWithoutSections);
    }, [API.getOffers?.data]);

    const mapBundlesToLayout = (bundles: OfferData[]) => {
        if (bundles.length === 0) return null;
        return (
            <BundlesLayout
                bundlesViewModel={bundlesViewModel}
                bundlesInternalViewModel={
                    publisherMetaData.storeTheme.general
                        .bundlesInternalViewModel
                }
                gapX={
                    bundlesInternalViewModel ===
                    EBundlesInternalViewModel.BAGELCODE
                        ? '4px'
                        : undefined
                }
                gapY={
                    bundlesInternalViewModel ===
                    EBundlesInternalViewModel.BAGELCODE
                        ? '8px'
                        : undefined
                }
                maxWidthOverride={
                    publisherMetaData.storeTheme.general
                        .bundlesInternalViewModel ===
                    EBundlesInternalViewModel.MAIN_SUB
                        ? '480px'
                        : '400px'
                }
                desktopMaxWidth={
                    isProductMadness ||
                    bundlesInternalViewModel ===
                        EBundlesInternalViewModel.PIPA ||
                    bundlesInternalViewModel ===
                        EBundlesInternalViewModel.PLAY_SIMPLE
                        ? '400px'
                        : '1250px'
                }
            >
                {bundles.map((bundle: OfferData, index: number) => (
                    <div key={index} className={'bundle'}>
                        <Bundle
                            index={index}
                            key={bundle.offerId}
                            data={bundle}
                            selectOffer={() => selectOffer(bundle)}
                        />
                    </div>
                ))}
            </BundlesLayout>
        );
    };

    return (
        <Box className={`bundles-list ${bundlesInternalViewModel}`}>
            {bundlesBySection &&
                Object.entries(bundlesBySection).map(
                    ([sectionId, sectionBundles], index) => {
                        const currentSection = sections?.find(
                            (item: any) => item?.sectionId === sectionId
                        );
                        return (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                key={index}
                            >
                                {currentSection && (
                                    <SectionDesign
                                        sectionName={currentSection.name}
                                        image={currentSection.image || ''}
                                    />
                                )}
                                {mapBundlesToLayout(sectionBundles)}
                            </Box>
                        );
                    }
                )}

            <Box
                sx={{
                    width: '100%',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {/*Make sure bundles without sections will be at the bottom of the page*/}
                {mapBundlesToLayout(bundlesWithoutSections)}
            </Box>
        </Box>
    );
};
