import Header from '../../components/HeaderWrapper/HeaderWrapper';
import { Bundle } from '../../components/Bundle/Bundle';
import useApi from '../../hooks/useApi';
import { BootResponse, OfferData } from '../../constants/apiResponses.types';
import LogoutModal from '../../components/LogoutModal/LogoutModal';
import {
    AcCircularLoader,
    ActionButton,
    LevelImageBlock,
    MessageBox,
    Overlay,
    PipaHeroBanner,
    ProductMadnessValueBlock,
    useIsDesktop,
    useIsLandscape
} from '@appcharge/shared-ui';
import SpecialOffer from '../../components/SpecialOffer/SpecialOffer';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
    EBundlesInternalViewModel,
    ECollectType,
    EEventsType,
    ELocalStorageKeys,
    EPopupSubType,
    EPublisherWebhookEventType,
    EPublisherWebhookOfferEventType,
    EQueryParams,
    EResultOptions,
    ESpecialOfferInternalViewModel,
    EStorePhase
} from '../../constants/enums';
import {
    OrderPopupEvents,
    collectImagesFromObject,
    formatOfferDataToPublisherWebhook,
    getBadgeName,
    getPlatformData,
    localStorageUtil
} from '../../utils';
import useUrlQuery from '../../hooks/useUrlQuery';
import './style.scss';
import { OfferBalance, ShopPageProps } from './shop.types';
import { Stack } from '@mui/material';
import useCustomEvents from '../../hooks/useCustomEvents';
import Footer from '../../components/footer/footer';
import Carousel from '../../components/Carousel/Carousel';
import CarouselItem from '../../components/Carousel/CarouselItem';
import { dailyBonusBadgeProps } from '../../components/HeaderWrapper/HeaderWrapper.types';
import { isAndroid, isIOS } from 'react-device-detect';
import { PopUpsHandlerV2 } from '../../components/PopUpsHandler/PopUpsHandlerV2';
import { useTranslation } from 'react-i18next';
import { BundlesList } from '../../components/BundlesList/BundlesList';
import { EOrderStatus } from '../checkout/checkout.types';
import Checkout from '../../components/Checkout/Checkout';
import useSaveToHomePage from '../../hooks/useSaveToHomePage';
import AddToHomePage from '../../components/AddToHomePage/AddToHomePage';

const jackpotSlotsValueBlock =
    'https://media.appcharge.com/media/shop-images/JMS-banner.png';
const bigFishValueBlock =
    'https://media.appcharge.com/media/shop-images/BFC-banner.png';
const jackpotSlotsDesktopLogo =
    'https://media.appcharge.com/media/shop-images/PJS--welcome-logo-desktop.png';
const bigFishDesktopLogo =
    'https://media.appcharge.com/media/shop-images/BFC--welcome-logo-desktop.png';
const CAROUSEL_LIMIT = 8;
const OFFERS_INTERVAL_TIME = 30000;
const TIME_LEFT_INTERVAL = 1000;

const ShopV2Page: React.FC<ShopPageProps> = ({ logout }) => {
    //store config states:
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [isProductMadness, setIsProductMadness] = useState(false);
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    //order states:
    const [orderProcessing, setOrderProcessing] = useState<string>();
    const [processing, setProcessing] = useState(false);
    const [orderState, setOrderState] = useState<string>();
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    //offer states:
    const [selectedOffer, setSelectedOffer] = useState<OfferData>();
    const [currentPopupDisplayed, setCurrentPopupDisplayed] = useState<
        OfferData | any
    >();
    const [popupOffers, setPopupOffers] = useState<OfferData[]>([]);
    const [isRefetching, setIsRefetching] = useState(false);
    const [specialOffersList, setSpecialOffersList] = useState<any>([]);
    const [bundlesList, setBundlesList] = useState<any[] | null>(null);
    const [dailyBonusBadges, setDailyBonusBadges] = useState<
        dailyBonusBadgeProps[]
    >([]);
    const [showFailCollect, setShowFailCollect] = useState(false);
    const [timeLeft, setTimeLeft] = useState<any>(null);
    const [timer, setTimer] = useState('');
    const [clickedClosePopups, setClickedClosePopups] = useState<string[]>([]);
    const [nextAvaliableDate, setNextAvaliableDate] = useState<any>(false);
    const [offersLoadedReported, setOffersLoadedReported] =
        useState<boolean>(false);
    const [postPurchaseId, setPostPurchaseId] = useState('');
    const [indexToCollect, setIndexToCollect] = useState(0);
    const [popUpStartHourUTC, setPopUpStartHourUTC] = useState<number>(0);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const API = useApi({ orderId: orderProcessing });
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const { bundlesInternalViewModel, specialOffersInternalViewModel } =
        publisherMetaData.storeTheme.general;
    const utmSource = useUrlQuery(EQueryParams.UTM_SOURCE);
    const [popupsPresented, setPopupsPresented] = useState<string[]>([]);
    const isLoginRedirect = useUrlQuery(EQueryParams.LOGIN_REDIRECT) === 'true';
    const customEvents = useCustomEvents();
    const [initialImagesLoadingTime, setInitialImagesLoadingTime] =
        useState<number>();
    const [arrOffersImages, setArrOffersImages] = useState<string[]>();

    const dailyBonusRef = useRef<OrderPopupEvents>(null);
    const postPurchaseRef = useRef<OrderPopupEvents>(null);
    const fetchInterval = useRef(0);

    const isDesktop = useIsDesktop();
    const isLandscape = useIsLandscape();
    const { isDisplayAddToHomePageButton } =
        useSaveToHomePage(publisherMetaData);
    const productMadnessValueBlock =
        bundlesInternalViewModel === 'jackpot-slots'
            ? jackpotSlotsValueBlock
            : bigFishValueBlock;
    const productMadnessDesktopLogo =
        bundlesInternalViewModel === 'jackpot-slots'
            ? jackpotSlotsDesktopLogo
            : bigFishDesktopLogo;

    // refetch order status when orderProcessing changes
    useEffect(() => {
        if (orderProcessing) {
            API.getOrder.refetch().catch((err) => {
                console.error(err);
                setShowFailCollect(true);
            });
        }
    }, [orderProcessing]);

    // check if order status changed
    useEffect(() => {
        if (orderProcessing && API.getOrder.data) {
            setOrderState(API.getOrder.data?.state);
        }
    }, [API.getOrder?.data]);

    useEffect(() => {
        if (orderProcessing === undefined) return;
        if (
            orderState === EOrderStatus.CHARGE_SUCCEED ||
            orderState === EOrderStatus.CHARGE_FAILED ||
            orderState === EOrderStatus.PAYMENT_FAILED
        ) {
            customEvents.sendCustomEvent(
                EEventsType.COLLECT_RESOLVED,
                {
                    offer_id: selectedOffer?.offerId,
                    result:
                        orderState === EOrderStatus.CHARGE_SUCCEED
                            ? EResultOptions.SUCCESS
                            : EResultOptions.FAILED,
                    offer_name: selectedOffer?.offerName,
                    platform: getPlatformData(),
                    type: selectedOffer?.offerType,
                    sub_type: selectedOffer?.subType,
                    reason:
                        orderState !== EOrderStatus.CHARGE_SUCCEED
                            ? orderState
                            : ''
                },
                EStorePhase.POST_LOGIN
            );
        }
        if (orderState === EOrderStatus.CHARGE_SUCCEED) {
            setProcessing(false);
            setShowPaymentModal(true);
        } else if (
            orderState === EOrderStatus.CHARGE_FAILED ||
            orderState === EOrderStatus.PAYMENT_FAILED
        ) {
            setProcessing(false);
            setShowFailCollect(true);
            setSelectedOffer(undefined);
        } else {
            const timer = setTimeout(() => {
                API.getOrder.refetch().catch((err) => console.error(err));
            }, TIME_LEFT_INTERVAL);
            return () => clearTimeout(timer);
        }
    }, [orderState, API.getOrder.isRefetching]);

    useEffect(() => {
        if (!API.getOffersV2.data) return;

        if (isRefetching !== API.getOffersV2.isRefetching) {
            setIsRefetching(API.getOffersV2.isRefetching);
        }

        setSpecialOffersList(API.getOffersV2.data.offers.promotions);

        setBundlesList(
            API.getOffersV2.data.offers.bundles?.map(
                (offer: OfferData, i: number) => (
                    <Bundle
                        index={i}
                        key={offer.offerId}
                        data={offer}
                        selectOffer={() => selectOffer(offer)}
                    />
                )
            )
        );
        // Sort and set popup offers by subType and priority
        const sortedPopups = API.getOffersV2.data.offers.popups?.sort(
            (a: any, b: any) => {
                const subTypeOrder = [
                    EPopupSubType.POST_PURCHASE,
                    EPopupSubType.DAILY_BONUS
                ];
                const aSubTypeIndex = subTypeOrder.indexOf(a.subType);
                const bSubTypeIndex = subTypeOrder.indexOf(b.subType);

                if (aSubTypeIndex !== bSubTypeIndex) {
                    return aSubTypeIndex - bSubTypeIndex;
                }

                // Sort by priority within the same subType
                return a.priority - b.priority;
            }
        );

        setPopupOffers(sortedPopups);

        if (
            popUpStartHourUTC !==
            API.getOffersV2?.data?.publisherSettings?.popUpStartHourUTC
        ) {
            setPopUpStartHourUTC(
                API.getOffersV2?.data?.publisherSettings?.popUpStartHourUTC
            );
        }

        if (!offersLoadedReported) {
            customEvents.sendCustomEvent(
                EEventsType.OFFERS_LOADED,
                {
                    specialOffers:
                        API.getOffersV2.data.offers.promotions?.length,
                    bundles: API.getOffersV2.data.offers.bundles?.length,
                    popups: API.getOffersV2.data.offers.popups?.length
                },
                EStorePhase.POST_LOGIN
            );
            API.sendPublisherWebhookEvent.mutate({
                eventType: EPublisherWebhookEventType.OFFER,
                data: {
                    eventName: EPublisherWebhookOfferEventType.OFFERS_LOADED,
                    countryCode: localStorageUtil.get(
                        ELocalStorageKeys.PLAYER_DATA
                    ).playerCountry,
                    offers: [
                        ...formatOfferDataToPublisherWebhook(
                            API.getOffersV2.data.offers.promotions
                        ),
                        ...formatOfferDataToPublisherWebhook(
                            API.getOffersV2.data.offers.bundles
                        ),
                        ...formatOfferDataToPublisherWebhook(
                            API.getOffersV2.data.offers.popups
                        )
                    ]
                }
            });
            setOffersLoadedReported(true);
        }

        if (
            !isProductMadness &&
            bundlesInternalViewModel &&
            [
                EBundlesInternalViewModel.JACKPOT_SLOTS,
                EBundlesInternalViewModel.BIG_FISH_CASINO
            ].includes(bundlesInternalViewModel)
        ) {
            setIsProductMadness(true);
        }
    }, [API.getOffersV2.data, API.getOffersV2.isRefetching]);

    useEffect(() => {
        const popupToDisplay =
            popupOffers &&
            popupOffers.length > 0 &&
            popupOffers.find((popup: OfferData) => {
                if (
                    popup.subType === EPopupSubType.POST_PURCHASE &&
                    postPurchaseId.length === 0
                )
                    return true; // Set the first POST_PURCHASE popup found
                else return popup.isAllowedToCollect && popup.indexToCollect;
            });
        if (popupToDisplay) {
            reportPopupImpression(popupToDisplay);
            setCurrentPopupDisplayed(popupToDisplay);
            if (popupToDisplay?.indexToCollect)
                setIndexToCollect(popupToDisplay?.indexToCollect - 1);
        } else {
            setCurrentPopupDisplayed(undefined);
        }
    }, [popupOffers]);

    useEffect(() => {
        const newBadges = popupOffers
            ?.filter((popup: OfferData) => {
                const isExistBadge = dailyBonusBadges?.some(
                    (badge) => badge.offerId === popup.offerId
                );
                const isExistBadgeCoverImage = popup?.offerUi?.badgeCoverImage;
                return (
                    !popup.isAllowedToCollect &&
                    !isExistBadge &&
                    popup.subType === EPopupSubType.DAILY_BONUS &&
                    isExistBadgeCoverImage
                );
            })
            .map((badge) => ({
                offerId: badge?.offerId,
                font: publisherMetaData.storeTheme.general.font,
                badgeCoverImage: badge?.offerUi?.badgeCoverImage,
                borderColor: badge?.offerUi?.borderColor || {},
                showEvery: badge?.showEvery
            }));

        if (newBadges && newBadges.length > 0) {
            setDailyBonusBadges((prevBadges: any) => [
                ...prevBadges,
                ...newBadges
            ]);
        }
        if (popUpStartHourUTC === undefined) return;
        const currentDate = new Date();
        const nextDate = new Date(currentDate);
        nextDate.setUTCHours(popUpStartHourUTC, 0, 0);
        // Check if the hour is before the current hour, if so, move to the next day
        if (nextDate.getTime() < currentDate.getTime()) {
            nextDate.setUTCDate(nextDate.getUTCDate() + 1);
        }
        setNextAvaliableDate(nextDate);
    }, [currentPopupDisplayed, popUpStartHourUTC, popupOffers, setPopupOffers]);

    useEffect(() => {
        if (!timeLeft) setTimer('');
        else {
            if (timeLeft < TIME_LEFT_INTERVAL) {
                setTimer('00:00:00');
            } else {
                const totalSeconds = Math.round(timeLeft / TIME_LEFT_INTERVAL);
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                setTimer(
                    `${hours.toString().padStart(2, '0')}:${minutes
                        .toString()
                        .padStart(2, '0')}:${seconds
                        .toString()
                        .padStart(2, '0')}`
                );
            }
        }
    }, [timeLeft]);

    useEffect(() => {
        const dailyBonusBadgesLength = dailyBonusBadges.length;
        if (dailyBonusBadgesLength > 0) {
            calculateTimeLeft(dailyBonusBadges[dailyBonusBadgesLength - 1]);
        }
    }, [dailyBonusBadges]);

    useEffect(() => {
        if (API.getOffersV2.isError) {
            customEvents.resetSession();
            logout();
        }
    }, [API.getOffersV2.isError]);

    const finishBundlesLoad = () => {
        setAllImagesLoaded(true);

        if (initialImagesLoadingTime) {
            const now = Date.now();

            const loadingTimeInMs = now - initialImagesLoadingTime;

            customEvents.sendCustomEvent(
                EEventsType.OFFERS_IMAGES_LOADED,
                {
                    offers:
                        API.getOffersV2.data.offers.bundles?.length +
                        API.getOffersV2.data.offers.promotions?.length +
                        API.getOffersV2.data.offers.popups?.length,
                    loadingTime: loadingTimeInMs,
                    images: arrOffersImages
                },
                EStorePhase.POST_LOGIN
            );
        }
    };

    useEffect(() => {
        let loadedImagesCount = 0;

        const allImagesSet: Set<string> = new Set();

        if (API.getOffersV2?.data) {
            if (API.getOffersV2?.data.offers.length === 0) {
                finishBundlesLoad();
                return;
            }

            API.getOffersV2?.data.offers?.bundles?.forEach(
                (bundle: OfferData) => {
                    collectImagesFromObject(bundle, allImagesSet);
                }
            );
            API.getOffersV2?.data.offers?.promotions?.forEach(
                (promotion: OfferData) => {
                    collectImagesFromObject(promotion, allImagesSet);
                }
            );
            API.getOffersV2?.data.offers?.popups?.forEach(
                (popup: OfferData) => {
                    collectImagesFromObject(popup, allImagesSet);
                }
            );
        }

        const allImagesArray = Array.from(allImagesSet);

        const isOnlyNoImage =
            allImagesArray.length === 1 && allImagesArray[0] === 'noImage';

        if (isOnlyNoImage) {
            finishBundlesLoad();
            return;
        }
        setArrOffersImages(allImagesArray);

        allImagesArray.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImagesCount += 1;
                if (
                    loadedImagesCount + 2 == allImagesArray.length ||
                    allImagesArray.length <= 2
                ) {
                    finishBundlesLoad();
                }
            };
            img.onerror = () => {
                customEvents.sendCustomEvent(
                    EEventsType.OFFERS_IMAGE_FAILED_LOADING,
                    {
                        offers:
                            API.getOffersV2.data.offers.bundles?.length +
                            API.getOffersV2.data.offers.promotions?.length +
                            API.getOffersV2.data.offers.popups?.length,
                        images: allImagesArray,
                        image: img.src
                    },
                    EStorePhase.POST_LOGIN
                );
                console.error('Failed to load:', img.src);
            };
        });
    }, [bundlesList]);

    useEffect(() => {
        const timer = setTimeout(() => {
            finishBundlesLoad();
        }, 10000);

        const now = Date.now();
        setInitialImagesLoadingTime(now);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    useEffect(() => {
        if (!publisherMetaData.supportConfiguration?.postLoginSnippet) {
            setIsScriptLoaded(true);
        }
        const backgroundImage =
            isDesktop || isLandscape
                ? publisherMetaData.storeTheme.general.backgroundImageDesktop
                : publisherMetaData.storeTheme.general.backgroundImageMobile;
        document.body.setAttribute(
            'style',
            `background-image: url(${backgroundImage}); background-size: cover; background-attachment: fixed; background-position: top center`
        );

        const checkIframeVisibility = () => {
            const iframe = document.getElementById('hs-web-sdk-iframe');
            if (
                iframe &&
                window.getComputedStyle(iframe).visibility === 'visible'
            ) {
                setIsScriptLoaded(true);
                return true;
            }
            return false;
        };

        const script = document.createElement('script');
        script.src = publisherMetaData.supportConfiguration?.postLoginSnippet;
        script.onload = () => {
            if (!checkIframeVisibility()) {
                const intervalId = setInterval(() => {
                    if (checkIframeVisibility()) {
                        clearInterval(intervalId);
                    }
                }, 100);
            }
        };
        document.body.appendChild(script);
        const onFocus = () => {
            customEvents.sendCustomEvent(
                EEventsType.SHOP_LAND,
                {
                    player_activity: 'test',
                    platform: getPlatformData(),
                    source: sessionStorage.getItem('source'),
                    add_to_home_screen_appear: isDisplayAddToHomePageButton
                },
                EStorePhase.POST_LOGIN
            );
            keepAlive();
        };
        const onBlur = () => {
            clearTimeout(fetchInterval.current);
        };
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('focus', onFocus);
            document.body.removeChild(script);
        };
        // eslint-disable-next-line
    }, [isDesktop, isLandscape]);

    useEffect(() => {
        if (!isLoggedIn) return;
        const init = async () => {
            customEvents.sendCustomEvent(
                EEventsType.SHOP_LAND,
                {
                    player_activity: isLoginRedirect ? 'post login' : 'normal',
                    platform: getPlatformData(),
                    source: sessionStorage.getItem('source'),
                    add_to_home_screen_appear: isDisplayAddToHomePageButton
                },
                EStorePhase.POST_LOGIN
            );
            await API.getPostLoginData.refetch();
            API.getPaypalKey.refetch();
        };
        init();
        return () => {
            clearTimeout(fetchInterval.current);
        };
        // eslint-disable-next-line
    }, [isLoggedIn]);

    const reportPopupImpression = (offer: OfferData) => {
        if (popupsPresented.includes(offer.offerId)) return;
        setPopupsPresented([...popupsPresented, offer.offerId]);
        API.sendPublisherWebhookEvent.mutate({
            eventType: EPublisherWebhookEventType.OFFER,
            data: {
                eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
                countryCode: localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)
                    .playerCountry,
                offers: formatOfferDataToPublisherWebhook([offer])
            }
        });
    };

    const keepAlive = async () => {
        API.keepAlive.mutate(
            { data: {}, headers: {} },
            {
                onError: () => {
                    customEvents.sendCustomEvent(
                        EEventsType.LOGOUT,
                        {
                            reason: 'session ended'
                        },
                        EStorePhase.PRE_LOGIN
                    );
                    customEvents.resetSession();
                    logout();
                },
                onSuccess: () => {
                    customEvents.setDistinctId();
                    API.getOffersV2.refetch();
                    fetchInterval.current = Number(
                        setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME)
                    );
                    if (!isLoggedIn) setIsLoggedIn(true);
                }
            }
        );
    };

    if (API.keepAlive.isIdle) {
        if (!localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
            window.location.href = `/login${
                utmSource ? `?utm_source=${utmSource}` : ''
            }`;
        } else {
            keepAlive();
        }
    }

    const openLogoutModal = () => {
        customEvents.sendCustomEvent(
            EEventsType.PROFILE_MENU_CLICKED,
            {},
            EStorePhase.POST_LOGIN
        );
        setShowLogoutModal(true);
    };

    const fetchIntervalHandler = () => {
        if (!document.hasFocus()) return;
        fetchInterval.current = Number(
            setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME)
        );
    };

    useEffect(() => {
        if (!publisherMetaData.featureFlags?.store_refresh_offers_stale_mode)
            return;

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                API.getOffersV2.refetch();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            );
        };
    }, [publisherMetaData.featureFlags]);

    const onCollect = async (item?: OfferData): Promise<void> => {
        setProcessing(true);
        const freeOrderData: any = {
            data: {
                clientGaId:
                    localStorage.getItem('ga_client_id') || '2134654321',
                paymentProvider: 'free',
                paymentMethod: 'free',
                utmSource: null,
                offerId: item?.offerId
            }
        };
        customEvents.sendCustomEvent(
            EEventsType.COLLECT_CLICKED,
            {
                offer_id: selectedOffer?.offerId,
                offer_name: selectedOffer?.offerName,
                platform: getPlatformData(),
                type: ECollectType.OFFER
            },
            EStorePhase.POST_LOGIN
        );

        API.createOrderV2.mutate(freeOrderData, {
            onSuccess: (orderData) => {
                setOrderProcessing(orderData.data.orderId);
            },
            onError: (error) => {
                console.error(error);
                setProcessing(false);
                navigate('../failed?msg=creating order error');
            }
        });
    };

    const selectOffer = (offerData: OfferData) => {
        const priceData = offerData?.productsSequence[indexToCollect]?.price;
        if (priceData.price === 0) {
            localStorageUtil.set(
                ELocalStorageKeys.IS_FREE_ORDER_SELECTED,
                true
            );
        } else {
            if (
                localStorageUtil.get(ELocalStorageKeys.IS_FREE_ORDER_SELECTED)
            ) {
                localStorageUtil.remove(
                    ELocalStorageKeys.IS_FREE_ORDER_SELECTED
                );
            }
        }
        setSelectedOffer(offerData);
        if (priceData.price === 0) {
            onCollect(offerData);
        } else {
            setShowPaymentModal(true);
            if (offerData.subType === EPopupSubType.POST_PURCHASE)
                setPostPurchaseId(offerData.offerId);
            else setPostPurchaseId('');
            const products = offerData?.productsSequence[
                indexToCollect
            ]?.products.map((product) => {
                const { name, quantity } = product;
                return {
                    name,
                    quantity
                };
            });
            customEvents.sendCustomEvent(
                EEventsType.OFFER_CLICKED,
                {
                    offer_id: offerData.offerId,
                    offer_name: offerData.offerName,
                    badge_left: getBadgeName(
                        'left',
                        offerData.dynamicOfferUi?.badges
                    ),
                    badge_right: getBadgeName(
                        'right',
                        offerData.dynamicOfferUi?.badges
                    ),
                    badge_center: getBadgeName(
                        'center',
                        offerData.dynamicOfferUi?.badges
                    ),
                    sale_percentage: offerData.dynamicOfferUi?.salePercentage,
                    type: offerData.offerType,
                    sub_type: offerData?.subType,
                    products,
                    bundle_price_usd: priceData.price,
                    player_local_currency: priceData.currencyCode,
                    bundle_price_total: priceData.price,
                    offer_id_external: offerData.publisherOfferId,
                    offer_internal_id: offerData.offerId
                },
                EStorePhase.POST_LOGIN
            );
        }
    };

    const closePopup = () => {
        setShowLogoutModal(false);
        setShowPaymentModal(false);
        setOrderProcessing('');
        setCurrentPopupDisplayed(undefined);
    };

    const isNoSpecialOffers =
        API.getOffersV2.data &&
        localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) &&
        bundlesList?.length === 0 &&
        specialOffersList?.length === 0;

    const getBalancesData = () => {
        const data: OfferBalance[] = API.getOffersV2?.data?.balances;
        if (!data) return;
        return data.map((item) => ({
            ...item,
            image: item.images.productPrefix || item.images.product,
            amount: item.quantity
        }));
    };

    const isDividerDisplay = [
        EBundlesInternalViewModel.LOCO_BINGO,
        EBundlesInternalViewModel.BAGELCODE,
        EBundlesInternalViewModel.PIPA,
        EBundlesInternalViewModel.PLAY_SIMPLE
    ].includes(bundlesInternalViewModel);

    const onTryAgain = () => {
        setShowFailCollect(false);
    };

    const assignNextPopUp = () => {
        if (!popupOffers || popupOffers.length <= 0) {
            setCurrentPopupDisplayed(undefined);
            return;
        }

        const eligibleOffer = popupOffers.find(
            (offer) =>
                !clickedClosePopups.includes(offer.offerId) &&
                currentPopupDisplayed?.offerId !== offer.offerId
        );

        if (eligibleOffer) {
            reportPopupImpression(eligibleOffer);
            if (eligibleOffer?.indexToCollect)
                setIndexToCollect(eligibleOffer.indexToCollect - 1);
            setCurrentPopupDisplayed(eligibleOffer);
        }
    };

    const calculateNextDate = (
        popupInterval: OfferData,
        nextAvaliableDate: Date | null
    ): Date => {
        const showEvery = popupInterval?.showEvery;
        const nextDate = nextAvaliableDate || new Date();

        if (showEvery && showEvery < 1440) {
            const currentDate = new Date();
            return new Date(currentDate.getTime() + showEvery * 60000);
        }
        return nextDate;
    };

    const calculateTimeLeft = (popupInterval: any) => {
        const nextDate = calculateNextDate(popupInterval, nextAvaliableDate);
        if (nextDate) {
            const interval = setInterval(() => {
                const remainingTime = nextDate.getTime() - new Date().getTime();
                setTimeLeft(remainingTime);

                if (remainingTime < TIME_LEFT_INTERVAL) {
                    clearInterval(interval);
                    API.getOffersV2.refetch();
                }
            }, TIME_LEFT_INTERVAL);
            return () => clearInterval(interval);
        }
    };

    //open next product badge
    const onClickBadge = (offerId?: string) => {
        const clickedPopupBadge = popupOffers.find(
            (offer) => offer.offerId === offerId
        );
        if (clickedPopupBadge) {
            setCurrentPopupDisplayed(null);
            setTimeout(() => {
                const updatedPopup = {
                    ...clickedPopupBadge,
                    isBadgeNextProduct: true
                };
                if (updatedPopup?.indexToCollect)
                    setIndexToCollect(updatedPopup.indexToCollect - 1);
                setCurrentPopupDisplayed(updatedPopup);
            }, 0);
        }
    };

    return (
        <div className="storeWrapper">
            <AddToHomePage
                publisherMetaData={publisherMetaData}
                customEvents={customEvents}
            />
            {(isAndroid || isIOS) && (
                <div
                    className={'shop__mobile-img'}
                    style={{
                        backgroundImage: `url(${publisherMetaData.storeTheme.general.backgroundImageMobile})`,
                        position: 'fixed',
                        zIndex: '-1',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}
                ></div>
            )}
            <Header
                showHeader={API.getOffersV2.data !== undefined}
                balanceQuantityFormat={
                    publisherMetaData.storeTheme.general.balanceQuantityFormat
                }
                playerName={
                    localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)
                        .playerName
                }
                openLogoutModal={openLogoutModal}
                logoSize={publisherMetaData.storeTheme.general.logoSize}
                balances={getBalancesData()}
                fontFamily={publisherMetaData.storeTheme.general.font}
                profileFrameImg={API.getOffersV2?.data?.profileFrameImg || ''}
                playerLevelImg={API.getOffersV2?.data?.playerLevelImg || ''}
                dailyBonusBadges={timeLeft ? dailyBonusBadges : []}
                timer={timer}
                onClickBadge={onClickBadge}
            />
            {(processing || !allImagesLoaded) && (
                <Overlay overlayPercentage={80} zIndex={10000}>
                    <AcCircularLoader text={t('processing')} />
                </Overlay>
            )}
            {!showPaymentModal && (
                <PopUpsHandlerV2
                    setProcessing={setProcessing}
                    onPurchaseClick={selectOffer}
                    currentPopupDisplayed={currentPopupDisplayed}
                    assignNextPopUp={assignNextPopUp}
                    setClickedClosePopups={setClickedClosePopups}
                    nextAvaliableDate={nextAvaliableDate}
                    setNextAvaliableDate={setNextAvaliableDate}
                    dailyBonusRef={dailyBonusRef}
                    postPurchaseRef={postPurchaseRef}
                    indexToCollect={indexToCollect}
                ></PopUpsHandlerV2>
            )}
            <div className="offers-wrapper">
                {isProductMadness && (
                    <ProductMadnessValueBlock
                        mobileLogoImage={
                            publisherMetaData.storeTheme.general.logo
                        }
                        desktopLogoImage={productMadnessDesktopLogo}
                        backgroundImage={productMadnessValueBlock}
                        playerLevelImage={API.getOffersV2?.data?.playerLevelImg}
                        storeSaleImage={
                            publisherMetaData.storeTheme.general?.storeSaleImage
                        }
                    ></ProductMadnessValueBlock>
                )}
                {[
                    EBundlesInternalViewModel.CASHMAN_CASINO,
                    EBundlesInternalViewModel.HEART_OF_VEGAS,
                    EBundlesInternalViewModel.LIGHTNING_LINK_CASINO
                ].includes(bundlesInternalViewModel) &&
                    API.getOffersV2?.data?.playerLevelImg && (
                        <LevelImageBlock
                            imageSrc={API.getOffersV2?.data?.playerLevelImg}
                        ></LevelImageBlock>
                    )}
                {API.getOffers?.data?.playerLevelImg &&
                    specialOffersInternalViewModel ===
                        ESpecialOfferInternalViewModel.PIPA && (
                        <PipaHeroBanner
                            backgroundImg={API.getOffers?.data?.playerLevelImg}
                        />
                    )}
                {specialOffersList?.length > 0 &&
                    (specialOffersInternalViewModel ===
                    ESpecialOfferInternalViewModel.PLAY_SIMPLE ? (
                        <div className="offers-wrapper--play-simple">
                            {specialOffersList.map(
                                (offer: OfferData, i: number) => (
                                    <SpecialOffer
                                        data={offer}
                                        selectOffer={() => selectOffer(offer)}
                                        key={i}
                                    />
                                )
                            )}
                        </div>
                    ) : (
                        <Carousel
                            showDivider={isDividerDisplay}
                            bundleViewModel={specialOffersInternalViewModel}
                        >
                            {specialOffersList
                                .slice(0, CAROUSEL_LIMIT)
                                .map((offer: OfferData, i: number) => (
                                    <CarouselItem
                                        key={i}
                                        data={offer}
                                        index={i}
                                    >
                                        <SpecialOffer
                                            data={offer}
                                            selectOffer={() =>
                                                selectOffer(offer)
                                            }
                                        />
                                    </CarouselItem>
                                ))}
                        </Carousel>
                    ))}
                {isNoSpecialOffers && (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        className="no_offers_box"
                        padding={'2vh'}
                    >
                        <MessageBox
                            title={
                                publisherMetaData.storeTheme.storeScreen
                                    .noOffersTitleText || t('shop.noOffers')
                            }
                            text={
                                publisherMetaData.storeTheme.storeScreen
                                    .noOffersMessageText ||
                                t('shop.noSpecialOffers')
                            }
                            fontFamily={
                                publisherMetaData.storeTheme.general.font
                            }
                            borderColor={
                                publisherMetaData.storeTheme.storeScreen
                                    .bundleBorderColor.colorOne
                            }
                        />
                    </Stack>
                )}
                <BundlesList selectOffer={selectOffer} />

                {bundlesInternalViewModel ===
                    EBundlesInternalViewModel.BAGELCODE &&
                    API.getOffersV2?.data?.playerLevelImg && (
                        <div className="player-level-image">
                            <img
                                src={publisherMetaData.storeTheme.general.logo}
                                alt="logo"
                            />
                            <img
                                alt="Player Level"
                                src={API.getOffersV2?.data?.playerLevelImg}
                            />
                        </div>
                    )}
            </div>
            <Footer
                showFooter={API.getOffersV2.data !== undefined}
                isScriptLoaded={isScriptLoaded}
            />
            {showLogoutModal && (
                <LogoutModal
                    playerName={
                        localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)
                            .playerName
                    }
                    closePopup={closePopup}
                    logout={logout}
                />
            )}
            {showFailCollect && (
                <div className={'special-free-offer__fail-screen'}>
                    <h2>{t('failed.somethingWentWrong')}</h2>
                    <ActionButton
                        className={
                            'special-free-offer__fail-screen__action-button'
                        }
                        borderRadius={'7px'}
                        text={'Back to Store'}
                        action={onTryAgain}
                    ></ActionButton>
                </div>
            )}
            {selectedOffer && showPaymentModal && (
                <Checkout
                    offerId={selectedOffer.offerId}
                    close={() => closePopup()}
                    setProcessing={setProcessing}
                    collectId={orderProcessing}
                    currencyCode={
                        selectedOffer?.productsSequence[indexToCollect]?.price
                            ?.currencyCode
                    }
                />
            )}
        </div>
    );
};

export default ShopV2Page;
