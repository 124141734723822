import useApi from '../../hooks/useApi';
import { Header, ProductMadnessHeader } from '@appcharge/shared-ui';
import { BootResponse } from '../../constants/apiResponses.types';
import { HeaderProps } from './HeaderWrapper.types';
import {
    EBalancesTheme,
    EBundlesInternalViewModel,
    ELocalStorageKeys
} from '../../constants/enums';
import { EPublisher, featureFlagsUtil, localStorageUtil } from '../../utils';
import { useEffect, useState } from 'react';

const HeaderWrapper = ({
    openLogoutModal,
    logoSize,
    playerName,
    balances,
    fontFamily,
    showHeader,
    balanceQuantityFormat,
    profileFrameImg,
    playerLevelImg,
    dailyBonusBadges,
    timer,
    onClickBadge
}: HeaderProps) => {
    const [showProductMadnessHeader, setShowProductMadnessHeader] =
        useState(false);
    const [shouldShowPlayerName, setShouldShowPlayerName] = useState(false);

    const pic =
        localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.profileImageUrl ||
        'https://media.appcharge.com/defaults/profile.png';
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const balanceTheme = publisherMetaData?.storeTheme.general?.balancesTheme
        ? publisherMetaData.storeTheme.general.balancesTheme
        : featureFlagsUtil.isPublisher(EPublisher.HUUUGE)
        ? EBalancesTheme.DEFAULT
        : featureFlagsUtil.isPublisher(EPublisher.BAGELCODE)
        ? EBalancesTheme.BAGELCODE
        : featureFlagsUtil.isPublisher(EPublisher.SPACEGO)
        ? EBalancesTheme.SPACEGO
        : featureFlagsUtil.isPublisher(EPublisher.PIPA)
        ? EBalancesTheme.PIPA
        : undefined;

    useEffect(() => {
        setShowProductMadnessHeader(
            [
                EBundlesInternalViewModel.JACKPOT_SLOTS,
                EBundlesInternalViewModel.BIG_FISH_CASINO
            ].includes(
                publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel
            )
        );
        const hasPlayerName: boolean =
            [
                EBundlesInternalViewModel.CASHMAN_CASINO,
                EBundlesInternalViewModel.HEART_OF_VEGAS,
                EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
                EBundlesInternalViewModel.PIPA
            ].includes(
                publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel
            ) || featureFlagsUtil.isPublisher(EPublisher.HUUUGE);
        setShouldShowPlayerName(hasPlayerName);
    }, [publisherMetaData?.storeTheme?.general]);

    const overrideStore = () => {
        const subDomain = window.prompt('?');
        localStorageUtil.set(
            ELocalStorageKeys.OVERRIDE_STORE,
            `${subDomain}.appcharge.com`
        );
    };

    return (
        <>
            {showProductMadnessHeader ? (
                <ProductMadnessHeader
                    profilePicUrl={pic}
                    playerName={playerName}
                    logoPicUrl={publisherMetaData.storeTheme.general.logo}
                    openLogoutModal={openLogoutModal}
                    overrideStore={overrideStore}
                    balances={balances}
                    balanceQuantityFormat={balanceQuantityFormat}
                    dailyBonusBadges={dailyBonusBadges}
                    timer={''}
                ></ProductMadnessHeader>
            ) : (
                <Header
                    profilePicUrl={pic}
                    profilePicBorder={
                        publisherMetaData.storeTheme.storeScreen
                            .bundleBorderColor
                    }
                    playerName={
                        featureFlagsUtil.isPublisher(EPublisher.HUUUGE) ||
                        shouldShowPlayerName
                            ? playerName
                            : ''
                    }
                    openLogoutModal={openLogoutModal}
                    logoSize={logoSize}
                    logoPicUrl={publisherMetaData.storeTheme.general.logo}
                    overrideStore={overrideStore}
                    balances={balances}
                    showHeader={showHeader}
                    fontFamily={fontFamily}
                    balanceQuantityFormat={balanceQuantityFormat}
                    balanceTheme={balanceTheme}
                    profileFrameImg={profileFrameImg}
                    backgroundColor={
                        balanceTheme === EBalancesTheme.PLAY_SIMPLE
                            ? 'rgb(0,0,0, 0.6)'
                            : 'transparent'
                    }
                    fullWidth={balanceTheme === EBalancesTheme.PLAY_SIMPLE}
                    playerLevelImg={playerLevelImg}
                    dailyBonusBadges={dailyBonusBadges}
                    timer={timer}
                    onClickBadge={onClickBadge}
                />
            )}
        </>
    );
};

export default HeaderWrapper;
